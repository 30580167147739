<template>
    <v-app-bar>
        <template #title>
            <v-btn prepend-icon="mdi-home"
                   variant="text"
                   @click="navigateTo('/')">
                Settings Service
            </v-btn>
        </template>
        <v-spacer></v-spacer>
        <!-- USER PROFILE -->
        <template v-if="user != undefined">
            <v-btn
                color="purple-lighten-5 text-purple-lighten-1"
                flat
                icon=""
                style="letter-spacing: 2px;"
                variant="elevated"
            >
                <strong>{{ userInitials }}</strong>

                <v-menu activator="parent" open-on-click open-on-hover>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <b>{{ userFullname }}</b>
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider/>
                        <v-list-item :to="{ name: 'logout' }" link>
                            <v-list-item-title>
                                <v-icon>mdi-logout</v-icon>
                                Abmelden
                            </v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item>
                          <v-list-item-title>Passwort ändern</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>Mein Konto</v-list-item-title>
                        </v-list-item> -->
                    </v-list>
                </v-menu>
            </v-btn>
        </template>
    </v-app-bar>
</template>

<script setup lang="ts">
import {useAuthorizationStore} from "@/stores/authorization-store";

const authStore = useAuthorizationStore();

const user = computed(() => {
    return authStore.getTokenPayload?.userData;
});

const userInitials = computed(() => {
    if (!user.value) return "";
    return user.value.firstname.at(0)! + user.value.lastname.at(0)!;
});

const userFullname = computed(() => {
    if (!user.value) return "";
    const {firstname, lastname} = user.value;
    return `${firstname} ${lastname}`;
});
</script>

<style scoped lang="scss"></style>
